import cx from 'classnames';

import { Layout } from 'layout';

import styles from 'components/PRISMIC/scss/pages.module.scss';

type SkeletonBoxProps = {
  className?: string;
  style?: Record<string, string | number>;
};

const SkeletonBox = ({ className, style }: SkeletonBoxProps) => (
  <div className={cx('skeleton', className)} style={style} />
);

const PageSkeleton = () => {
  return (
    <Layout addMinHeight={false}>
      <div className="prismic-page relative w-full bg-white pt-10 tracking-[-0.025em] text-[#333]">
        <div className={cx(styles.page, styles.container)}>
          <div className={cx(styles.row)}>
            <div className={cx(styles['col-lg-3'])}>
              <SkeletonBox className="w-full" style={{ height: '400px' }} />{' '}
              {/* Navigation placeholder */}
            </div>
            <div className={cx(styles['col-lg-9'], styles['pages-content'])}>
              <div className={cx(styles['m1'], styles['module'])}>
                <SkeletonBox
                  className="mb-4"
                  style={{ width: '200px', height: '20px' }}
                />{' '}
                {/* Breadcrumbs placeholder */}
                <SkeletonBox className="mb-6" style={{ height: '40px' }} />{' '}
                {/* Title placeholder */}
                {[1, 2, 3].map(i => (
                  <SkeletonBox
                    key={i}
                    className="mb-4"
                    style={{ height: '20px' }}
                  /> /* Content placeholder */
                ))}
              </div>
              <div className={cx(styles.row)}>
                <div className={cx(styles['col-lg-8'])}>
                  {[1, 2, 3, 4, 5].map(i => (
                    <SkeletonBox
                      key={i}
                      className="mb-4"
                      style={{ height: '20px' }}
                    /> /* Main column content placeholder */
                  ))}
                </div>
                <div className={cx(styles['col-lg-4'])}>
                  {[1, 2, 3].map(i => (
                    <SkeletonBox
                      key={i}
                      className="mb-4"
                      style={{ height: '20px' }}
                    /> /* Side column content placeholder */
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageSkeleton;
