import cx from 'classnames';
import { createContext } from 'react';

import styles from 'components/PRISMIC/scss/pages.module.scss';
import { Layout } from 'layout';

import Navigation from 'components/PRISMIC/Navigation';
import { PrismicNavigationProps } from 'components/PRISMIC/PrismicNavigation/util';
import { PageDocument } from 'prismic-types';
import PageContent from './PageContent';
import PageHeader from './PageHeader';

export type PageProps = {
  page: PageDocument;
  navigation: PrismicNavigationProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staticProps: Record<string, any>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StaticPropsContext: React.Context<Record<string, any>> =
  createContext({});

export default function PrismicPage({
  page,
  navigation,
  staticProps
}: PageProps) {
  const showNavigationColumn = page.data.show_navigation_column;

  return (
    <Layout
      pageTitle={page.data.title as string}
      addMinHeight={false}
      isDarkMode
    >
      <StaticPropsContext value={staticProps}>
        <div
          className={cx(
            'prismic-page relative w-full bg-white pt-10 tracking-[-0.025em] text-[#333]',
            '[&_a]:underline [&_ol]:mb-6 [&_ol]:list-decimal [&_ol]:pl-10 [&_p]:mb-6 [&_ul]:mb-6 [&_ul]:list-disc [&_ul]:pl-10',
            styles.pad1
          )}
        >
          <div className={cx(styles.page, styles.container)}>
            <div className={cx(showNavigationColumn && styles.row)}>
              {showNavigationColumn && <Navigation root={navigation.root} />}
              <div
                className={cx(
                  showNavigationColumn && styles['col-lg-9'],
                  showNavigationColumn && styles['pages-content']
                )}
              >
                <PageHeader navigation={navigation} page={page} />
                <PageContent page={page} />
              </div>
            </div>
          </div>
        </div>
      </StaticPropsContext>
    </Layout>
  );
}
