import { SliceZone } from '@prismicio/react';
import cx from 'classnames';

import styles from 'components/PRISMIC/scss/pages.module.scss';
import { components } from 'components/PRISMIC/slices';
import Breadcrumbs from 'components/pages/prismic/Breadcrumbs';

import { PageProps } from '.';
import { PageLayoutContext } from './PageContent';

export default function PageHeader({
  navigation,
  page
}: Omit<PageProps, 'staticProps'>) {
  const showNavigationColumn = page.data.show_navigation_column;

  return (
    <div className={cx(styles['m1'], styles['module'])}>
      {navigation.breadcrumbs && (
        <Breadcrumbs breadcrumbs={navigation.breadcrumbs} />
      )}
      <h1 className={cx(styles.h1, styles['h1--alt'], styles.m2)}>
        {page.data.title}
      </h1>
      <PageLayoutContext value={showNavigationColumn ? 3 / 4 : 1}>
        <SliceZone slices={page.data.slices2} components={components} />
      </PageLayoutContext>
    </div>
  );
}
