import cx from 'classnames';
import styles from 'components/PRISMIC/scss/pages.module.scss';
import { NavigationSection } from 'components/PRISMIC/PrismicNavigation/util';
import { isEmpty } from 'lodash-es';

const NavigationLink = ({ title, href }: { title: string; href: string }) => (
  <a href={href} className={cx(styles.l3, styles['submenu__link'])}>
    {title}
  </a>
);

const NavigationSubList = ({ section }: { section: NavigationSection }) => {
  const childSections = section.children?.filter(
    it => it.data.show_in_navigation
  );

  return (
    <>
      <NavigationLink href={section.url} title={section.data.title || ''} />
      {!isEmpty(childSections) && (
        <ul className={styles['submenu__list']}>
          {childSections!.map(childSection => (
            <li
              key={childSection.id}
              className={cx(
                styles['submenu__item'],
                childSection.active && styles['submenu__item--active']
              )}
            >
              <NavigationSubList
                key={childSection.uid}
                section={childSection}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default (props: { root?: NavigationSection | null }) => {
  if (!props.root)
    return (
      <div className={styles['col-lg-3']}>
        <nav className={styles.submenu}></nav>
      </div>
    );

  return (
    <div className={styles['col-lg-3']}>
      <nav className={styles.submenu}>
        <h3
          className={cx(
            styles['submenu__heading'],
            props.root.active && styles['submenu__heading--active'],
            props.root.data.parent_section && styles['submenu__heading--usable']
          )}
        >
          <NavigationLink
            href={props.root?.url}
            title={props.root.data.title || ''}
          />
        </h3>
        <div className={styles['submenu__lists']}>
          <ul className={styles['submenu__list']}>
            {props.root.children
              ?.filter(it => it.data.show_in_navigation)
              .map(childSection => (
                <li
                  key={childSection.uid}
                  className={cx(
                    styles['submenu__item'],
                    childSection.active && styles['submenu__item--active']
                  )}
                >
                  <NavigationSubList section={childSection} />
                </li>
              ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};
