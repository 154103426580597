import { GetStaticPropsContext } from 'next';
import { Page as PrismicPage } from 'pages/pages/[uid]';
import {
  getLandingPageDocument,
  getNonPagesPaths,
  getPageDocument
} from 'lib/prismic';
import { createClient } from '../../prismicio';
import { getAllSections } from '../../components/pages/prismic/utils';
import { getLeafSectionFromPath } from '../../components/PRISMIC/PrismicNavigation/util';

export default PrismicPage;

type PageParams = { prismicPath: string[] };

export async function getStaticProps({
  params,
  previewData
}: GetStaticPropsContext<PageParams>) {
  const client = createClient({ previewData });
  const path: string[] | undefined = params?.prismicPath;
  // General/Old Catchall that handles if, for some reason, PrismicPath is undefined
  // This will lead to another short circuit
  if (!path) {
    return { notFound: true };
  }
  // Emergency quick catch all
  // Seems like nextJS will ALWAYS return params.prismicPath but for nextJS pages, it returns an array
  // where the first value is always '_next'
  // So we short circuit here to avoid any further computations
  if (path?.[0] === '_next') {
    return { notFound: true };
  }

  const sections = await getAllSections(client);
  const leafSection = getLeafSectionFromPath(path, sections);
  // @ts-ignore
  const pageType = leafSection?.data?.default_page?.type;

  switch (pageType) {
    case 'landingPage':
      return getLandingPageDocument(leafSection, client, path);

    default:
      return getPageDocument(leafSection, client, path, sections);
  }
}

export async function getStaticPaths() {
  const shouldBuildAllPages =
    process.env.BUILD_ALL_PAGES === 'true' &&
    process.env.APP_ENV === 'production';

  if (shouldBuildAllPages) {
    try {
      const nonPagesPaths = await getNonPagesPaths();
      // return Non Pages Paths
      return {
        paths: nonPagesPaths,
        fallback: true
      };
    } catch (error) {
      console.error('Error in getStaticPaths:', error);
      // Return empty paths with blocking fallback as a safe fallback
      return {
        paths: [],
        fallback: 'blocking'
      };
    }
  }

  return {
    paths: [],
    fallback: 'blocking'
  };
}
