import { FilledContentRelationshipField } from '@prismicio/client';
import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { createClient } from 'prismicio';

import PrismicPage from 'components/pages/prismic/Page';
import PageSkeleton from 'components/pages/prismic/Skeleton';
import {
  getAllSections,
  staticPropSlicesMap
} from 'components/pages/prismic/utils';
import {
  buildNavigation,
  PrismicNavigationProps
} from 'components/PRISMIC/PrismicNavigation/util';
import {
  LandingPageDocument,
  PageDocument,
  PageDocumentDataSlices1Slice,
  PageDocumentDataSlices2Slice,
  PageDocumentDataSlicesSlice,
  PageSectionDocument
} from 'prismic-types';
import LandingPage from './landingPage/[uid]';

import { sendErrorToServices } from 'lib/logging';
import type { JSX } from 'react';

type PageProps = {
  page: PageDocument | LandingPageDocument;
  navigation: PrismicNavigationProps;
  staticProps: Record<string, unknown>;
};

type PageParams = { uid: string };

export type PageSlice =
  | PageDocumentDataSlicesSlice
  | PageDocumentDataSlices1Slice
  | PageDocumentDataSlices2Slice;

export const Page = ({
  page,
  navigation,
  staticProps
}: PageProps): JSX.Element => {
  const router = useRouter();

  if (router.isFallback) {
    return <PageSkeleton />;
  }

  if (page.type === 'landingPage')
    return <LandingPage page={page as LandingPageDocument}></LandingPage>;

  return (
    <>
      <Head>
        <title>{page.data.title}</title>
        {page.data.keywords && (
          <meta name="keywords" content={page.data.keywords} />
        )}
        {page.data.description && (
          <meta name="description" content={page.data.description} />
        )}
        {(page.data.thumbnail?.url || page.data.legacy_thumbnail_url) && (
          <meta
            property="og:image"
            content={
              page.data.thumbnail?.url || page.data.legacy_thumbnail_url!
            }
          />
        )}
        {page.data.custom_metadata?.map(
          item =>
            item.key &&
            item.value && (
              <meta key={item.key} name={item.key} content={item.value} />
            )
        )}
      </Head>

      <PrismicPage
        page={page}
        navigation={navigation}
        staticProps={staticProps}
      />
    </>
  );
};

export default Page;

export async function buildStaticPropsFromSlices(page: PageDocument) {
  const slices: PageSlice[] = (page.data.slices as PageSlice[])
    .concat(page.data.slices1 as PageSlice[])
    .concat(page.data.slices2 as PageSlice[]);

  const staticProps: Record<string, unknown> = {};

  await Promise.all(
    slices
      .map(async it => {
        if (!staticPropSlicesMap[it.slice_type]) return;
        staticProps[it.id] = await staticPropSlicesMap[it.slice_type]?.(it);
      })
      .filter(it => it)
  );
  return staticProps;
}

export async function getStaticProps({
  params,
  previewData
}: GetStaticPropsContext<PageParams>): Promise<
  GetStaticPropsResult<PageProps>
> {
  try {
    const client = createClient({ previewData });
    const uid = params?.uid;
    if (!uid) {
      return {
        notFound: true
      };
    }

    const page = await client.getByUID('page', uid).catch(() => null);
    if (!page) {
      return {
        notFound: true
      };
    }

    const sections: PageSectionDocument[] = await getAllSections(client);

    const currentPageSection: FilledContentRelationshipField<'page_section'> =
      page.data.section as FilledContentRelationshipField<'page_section'>;

    const navigation = buildNavigation(
      currentPageSection.id,
      sections,
      page.uid
    );
    if (!navigation) {
      return {
        notFound: true
      };
    }

    return {
      props: {
        page,
        navigation,
        staticProps: await buildStaticPropsFromSlices(page)
      }
    };
  } catch (error) {
    sendErrorToServices('Error in getStaticProps', error);
    return {
      notFound: true
    };
  }
}

export async function getStaticPaths() {
  const shouldBuildAllPages =
    process.env.BUILD_ALL_PAGES === 'true' &&
    process.env.APP_ENV === 'production';

  if (shouldBuildAllPages) {
    const client = createClient();
    const pages = await client.getAllByType('page');

    return {
      paths: pages.map(page => ({
        params: { uid: page.uid }
      })),
      fallback: true
    };
  }

  return {
    // We are setting an empty array here because we are using fallback: "blocking" and ISR
    // https://nextjs.org/docs/pages/api-reference/functions/get-static-paths#fallback-blocking
    // https://vercel.com/guides/how-do-i-reduce-my-build-time-with-next-js-on-vercel
    // (faster builds, but slower initial page load)
    paths: [],
    fallback: 'blocking'
  };
}
